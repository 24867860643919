$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
@import "~bootstrap-sass/assets/stylesheets/bootstrap";

#layout {
  width: 100%;

  background: #43c6ac; /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #e8f6f3, #bdfff3);
  background: linear-gradient(to bottom, #e8f6f3, #bdfff3);

  .navbar {
    @extend .row;
    display: flex;
    flex-direction: row;
    position: fixed;
    left: 0;
    width: 100%;
    height: 5rem;
    margin: 0;
    padding: 0 1rem;
    border: 0;

    .nav-links {
      @include make-sm-column-offset(8);
      @extend .col-sm-4;

      .nav-link {
          @extend .col-xs-2;
          padding: 1rem 0;
          font-size: 1.25rem !important;
          display: inline;
          text-align: center;
          border-style: solid;
          border-width: 3px 0 0 0;
          border-color: transparent;

          &.active {
            border-color: #00ade6;
            border-image: -moz-linear-gradient(top,#00ade6 0,#05e3d5 100%);
            border-image: -webkit-linear-gradient(top,#00ade6,#05e3d5);
            border-image: -o-linear-gradient(top,#00ade6 0,#05e3d5 100%);
            border-image: -ms-linear-gradient(top,#00ade6 0,#05e3d5 100%);
            border-image: linear-gradient(180deg,#00ade6 0,#05e3d5);
            border-image-slice: 2;
          }
      }
    }
  }

  .container.wrapper {
    width: 100%;
    height: calc(100% - 9rem);

    .content {
      min-width: 500px;
      margin: 5rem auto;
      padding: 1.5rem;
    }
  }

  .footer {
    @extend .col-md-12;
  }
}
