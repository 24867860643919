.hidden {
  visibility: hidden;
}

.bouncing {
  animation: bouncing 2s infinite;
  -moz-animation: bouncing 2s infinite;
  -webkit-animation: bouncing 2s infinite;
}

.fadeInFast {
  animation: fadeIn 0.5s infinite;
  -moz-animation: fadeIn 0.5s infinite;
  -webkit-animation: fadeIn 0.5s infinite;
}

@keyframes bouncing {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(30%);
  }

  100% {
    transform: translateY(0%);
  }
}


@-webkit-keyframes bouncing {
  0% {
    -webkit-transform: translateY(0%);
  }

  50% {
    -webkit-transform: translateY(30%);
  }

  100% {
    -webkit-transform: translateY(0%);
  }
}


@-moz-keyframes bouncing {
  0% {
    -moz-transform: translateY(0%);
  }

  50% {
    -moz-transform: translateY(30%);
  }

  100% {
    -moz-transform: translateY(0%);
  }
}


.horizontal.bouncing {
  animation: bouncingHorizontal 1.5s infinite;
  -moz-animation: bouncingHorizontal 1.5s infinite;
  -webkit-animation: bouncingHorizontal 1.5s infinite;
}

@keyframes bouncingHorizontal {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(10%);
  }

  100% {
    transform: translateX(0%);
  }
}


@-webkit-keyframes bouncingHorizontal {
  0% {
    -webkit-transform: translateX(0%);
  }

  50% {
    -webkit-transform: translateX(10%);
  }

  100% {
    -webkit-transform: translateX(0%);
  }
}


@-moz-keyframes bouncingHorizontal {
  0% {
    -moz-transform: translateX(0%);
  }

  50% {
    -moz-transform: translateX(10%);
  }

  100% {
    -moz-transform: translateX(0%);
  }
}


.slideInLeft {
  animation: slideInLeft 1s ease-out;
  -moz-animation: slideInLeft 1s ease-out;
  -webkit-animation: slideInLeft 1s ease-out;
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}


@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-200px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }
}


@-moz-keyframes slideInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-200px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0px);
  }
}


.slideInRight {
  animation: slideInRight 1s ease-out;
  -moz-animation: slideInRight 1s ease-out;
  -webkit-animation: slideInRight 1s ease-out;
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}


@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}


@-moz-keyframes slideInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}


.slideInBottom {
  animation: slideInBottom 1s ease-out;
  -moz-animation: slideInBottom 1s ease-out;
  -webkit-animation: slideInBottom 1s ease-out;
}

@keyframes slideInBottom {
  0% {
    opacity: 0;
    transform: translateY(75%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}


@-webkit-keyframes slideInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(75%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}


@-moz-keyframes slideInBottom {
  0% {
    opacity: 0;
    -moz-transform: translateY(75%);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0%);
  }
}


.fadeIn {
  &.one {
    animation: fadeIn 1s ease-out;
    -moz-animation: fadeIn 1s ease-out;
    -webkit-animation: fadeIn 1s ease-out;
  }
  &.two {
    animation: fadeIn 2s ease-out;
    -moz-animation: fadeIn 2s ease-out;
    -webkit-animation: fadeIn 2s ease-out;
  }
  &.four {
    animation: fadeIn 4s ease-out;
    -moz-animation: fadeIn 4s ease-out;
    -webkit-animation: fadeIn 4s ease-out;
  }
  &.six {
    animation: fadeIn 6s ease-out;
    -moz-animation: fadeIn 6s ease-out;
    -webkit-animation: fadeIn 6s ease-out;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
